
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useRouter } from "vue-router";
import {mask} from 'vue-the-mask'
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import {ActionsRe} from "@/store/enums/StoreResearchEnums";
import {active} from "@/core/data/genericData";
import Quill from "quill/dist/quill.js";

export default defineComponent({
  name: "edit-categories-modal", 
  directives: {mask}, 
  props: {
    id: { required: true },
    // data: {
    //     type: Object
    // }
  },
  components: { 
      
    },   
    methods: {
    onChange() 
    { 
      this.formData['category_select'] = '' 
      }
  },

  setup(props) {   
   const active_list_data = active; 
    // this.$forceUpdate();
    var obj1: any[] = []
    var tag_list: any[] = []
    const router = useRouter();    
    var formData = ref({})
    const store = useStore();

    let rawImg = ref() ;
    function encodeImagetoBase64L(element) {
        const image = element.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(image);
        
        reader.onload = (event) => {       
        var image_encode = String(reader.result)
        if (image_encode){
           var image_slice = image_encode.split(",")[1]
            rawImg.value = {
          file_name:element.target.files[0].name,
          file_data: image_slice,
          }
        }
        else{
          rawImg.value = {
          file_name:element.target.files[0].name,
          file_data:reader.result,
          }
        }
       
       };
      
      }
    
    const setFormData = async (id) => {    

        var j = 0;
        try {

        var values = {"news_id": id }          
        
         await store.dispatch(Actions.CUST_NEWS_EDIT, values).then(({ data }) => { 
          //var quill = new Quill('#kt_forms_widget_1_editor', {
            //theme: 'snow'
          //});     
          //var descrip =  quill.root.innerHTML = data.news_description;
          
          formData.value = {
            id : data.news_id ,
            name: data.news_title,
            description: data.news_description,
            short_description: data.news_short_description,
            category_select:  data.category_id,
            parent_select: data.parent_category_id, 
            news_select: data.news_type_id,   
            tag_select: data.news_tags_json,
            active : data.active,
            };
          console.log(formData.value)
          })
          .catch(({ response }) => {

            console.log(response);

          });
          
        } catch (e) {
          console.log(e);
        } 
        
    }

    const taglist = ref([]);
    const setTagData = async (data) => {
        //loading.value = true;
        try {
          var values = {"search_term": data}
          await store.dispatch(Actions.CUST_TAG_LIST, values).then(({ data }) => {
          taglist.value = data.page_data;
          //loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }
    

    onMounted( async () => {   
      setCurrentPageBreadcrumbs("News", []);
      await setFormData(props.id);
      await setTagData(taglist.value)
      await setParentCatData()
      await setChildCatData()
      await setNewsType()
      const editorId = "kt_forms_widget_1_editor";

      // init editor
      const options = {
        modules: {
          toolbar: {
            container: "#kt_forms_widget_1_editor_toolbar",
          },
        },
        theme: "snow",
      };

      // Init editor
      new Quill("#" + editorId, options);
      });  

    
    const formRef = ref<null | HTMLFormElement>(null);
    const editNewsModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);    
    //const loadingHsn = ref<boolean>(false);

  const rules = ref({
      name: [
        {
          required: true,
          message: "Name is required",
          trigger: "change",  
          //pattern:"^[a-zA-Z]+$",        
        },        
      ],    
      description: [
        {
          required: true,
          message: "Description is required",
          trigger: "change",  
        }
      ],
      short_description: [
        {
          required: true,
          message: "Short Description is required",
          trigger: "change",  
        }
      ],
      news_select: [
        {
          required: true,
          message: "News Type is required",
          trigger: "change",  
        }
      ],
       tag_select: [
        {
          required: true,
          message: "Tag is required",
          trigger: "click",  
        }
      ],
    });


  const newstype = ref([]);
     const setNewsType = async () => {
        loading.value = true;
        try {
          var values = {}
          await store.dispatch(Actions.CUST_NEWS_TYPE, values).then(({ data }) => {
          newstype.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const parentcat = ref([]);
     const setParentCatData = async () => {
        loading.value = true;
        try {
          var values = {}
          await store.dispatch(Actions.CUST_NEWS_PARENT_CATEGORY, values).then(({ data }) => {
          parentcat.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

     const childcat = ref([]);
     const setChildCatData = async () => {
      
        loading.value = true;
        try {
          var values = {
            "category_id": formData.value['parent_select']
          }
          await store.dispatch(Actions.CUST_NEWS_CHILD_CATEGORY, values).then(({ data }) => {
          childcat.value = data;
          loading.value = false;
         
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const addtag : any[] = [];
    const setNewsData = async (data) => {   
      //var user = JSON.parse(JwtService.getToken());     
       var tag_id_list : any [] = []
      var tag_text_list : any [] = []
      for (let j = 0; j < data.tag_select.length; j++){
          if (data.tag_select[j]){
            tag_text_list.push(data.tag_select[j])
          }
      }
      for (let i=0; i<tag_text_list.length; i++)
      {
        const db_data = {
          "tag_name": tag_text_list[i],
          "created_user_id" : 1
          }
           await store.dispatch(Actions.CUST_ADD_TAG, db_data).then(({ data }) => {
           
           addtag.push(data.tag_id);
           
          })
      }
      const merge = tag_text_list;
      // var quill = new Quill('#kt_forms_widget_1_editor', {
      //       theme: 'snow'
      //     });    
      //      var justTextContent = document.getElementById('kt_forms_widget_1_editor');    
      //      var descrip = quill.root.innerHTML;
      const db_data = {
        "news_id": data.id,
        "news_title": data.name,
        "news_description": data.description,
        "news_short_description": data.short_description,
        "category_id": data.category_select,
        "parent_category_id": data.parent_select,
        "news_image_name": rawImg.value.file_name,
        "news_image_data": rawImg.value.file_data,
        "news_tags_json": merge,
        "news_type_id": data.news_select,
        "news_read_views":2,
        "is_active": data.active,
        }

        await store.dispatch(Actions.CUST_NEWS_SAVE, db_data).then(({ data }) => {
        if (data)
        {
            setTimeout(() => {
              loading.value = false;
              Swal.fire({
                text: "News has been successfully Updated.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(editNewsModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
        })
      .catch(({ response }) => {
        console.log(response);        
      });
    };

   const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setNewsData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });  
    }
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      setTagData,
      setParentCatData,
      setChildCatData,
      setNewsType,
      encodeImagetoBase64L,
      rawImg,
      taglist,
      parentcat,
      newstype,
      childcat,
      editNewsModalRef,
      tag_list,
      active_list_data,
      
    };
  },
});
